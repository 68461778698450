import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    HostListener,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DialogSimpleComponent } from '../shared/dialog-simple/dialog-simple.component';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
    loginFormGroup: FormGroup;
    invalidCredentials = false;
    loginError = false;
    httpErrorResponse: HttpErrorResponse;
    isShowPassword = false;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private matDialog: MatDialog
    ) {}

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        if (event.code === 'Enter') {
            this.login();
        }
    }

    ngOnInit(): void {
        this.loginFormGroup = this.fb.group({
            username: this.fb.control(null, Validators.required),
            password: this.fb.control(null, Validators.required),
        });
        if (this.authService.isLoggedIn()) {
            this.router.navigate(['']).then();
        }

        this.authService.user.subscribe({
            next: (value) => {
                this.loginError = false;
                this.invalidCredentials = false;

                if (value) {
                    this.httpErrorResponse = value.httpErrorResponse;
                    if (value.error) {
                        this.loginError = true;
                    }
                    if (!value.authenticated) {
                        this.invalidCredentials = true;
                    }

                    if (
                        value.httpErrorResponse
                            ? value.httpErrorResponse.status === 400
                            : false
                    ) {
                        this.invalidCredentials = false;
                        this.matDialog.open(DialogSimpleComponent, {
                            data: {
                                title: 'Failed Login Attempts Exceeded',
                                message: `Dear ${
                                    this.loginFormGroup.get('username').value
                                }, you have exceeded your login attempts.\n
                                Please contact Administrator to unlock your account and reset your login password. \n \n
                                Administrator is reachable at: \n
                                <b>Phone no:</b> 2433777 \n <b>Whatsapp:</b> +6738976337 \n
                                <b>Email:</b> nbt.customercare@inchcape.com.bn`,
                                buttonPositive: 'Okay',
                            },
                            panelClass: 'mat-dialog-sm',
                        });
                    }
                }
            },
        });
    }

    login(): void {
        this.invalidCredentials = false;
        this.loginError = false;
        const username = this.loginFormGroup.get('username').value;
        const password = this.loginFormGroup.get('password').value;
        this.authService.login(username, password);
    }

    requestPasswordEmail(): void {
        this.router.navigate(['reset-password']);
    }

    showPassword() {
        this.isShowPassword = !this.isShowPassword;
    }
}
