import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { passwordValidator } from '../../shared/validator/password.validator';
import { Subscription } from 'rxjs';
import { ApplicationUserModel } from '../../model/application-user.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DialogSimpleComponent } from 'src/app/shared/dialog-simple/dialog-simple.component';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'app-reset-password-user',
    templateUrl: './reset-password-email.component.html',
    styleUrls: ['./reset-password-email.component.scss'],
})
export class ResetPasswordApplicationUserComponent implements OnDestroy {
    applicationUserFormGroup: FormGroup;
    applicationUserModel: ApplicationUserModel;
    isSaving: boolean = false;
    updateSubscription: Subscription;
    resetPasswordKey: string = '';

    constructor(
        private formBuilder: FormBuilder,
        private usersService: UsersService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        private route: ActivatedRoute,
        private matDialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe({
            next: (queryParam) => {
                if (queryParam.key) {
                    this.resetPasswordKey = queryParam.key;
                    this.applicationUserFormGroup = this.formBuilder.group(
                        {
                            oldPassword: this.formBuilder.control(null),
                            password: this.formBuilder.control(null),
                            newPassword: this.formBuilder.control(null, Validators.required),
                            repeatPassword: this.formBuilder.control(null, Validators.required),
                            email: this.formBuilder.control(null),
                            key: this.formBuilder.control({
                                value: this.resetPasswordKey,
                                disabled: true
                            })
                        },
                        {
                            validators: [passwordValidator()],
                        }
                    );
                } else {
                    this.applicationUserFormGroup = this.formBuilder.group(
                        {
                            oldPassword: this.formBuilder.control(null),
                            password: this.formBuilder.control(null),
                            newPassword: this.formBuilder.control(null),
                            repeatPassword: this.formBuilder.control(null),
                            email: this.formBuilder.control(null, Validators.required),
                            key: this.formBuilder.control({
                                value: null,
                                disabled: true
                            })
                        },
                        {
                            validators: [passwordValidator()],
                        }
                    );
                }
            }
        })
    }

    ngOnDestroy() {
        if (this.updateSubscription) {
            this.updateSubscription.unsubscribe();
        }
    }

    save(): void {
        this.isSaving = true;
        if (!this.resetPasswordKey) {
            this.updateSubscription = this.usersService.requestEmail(this.applicationUserFormGroup.getRawValue()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.router.navigate(['']);
                },
                error: (value) => {
                    this.isSaving = false;
                    this.snackBar.open(value.error.message, 'Close');
                }
            });
        } else {
            this.updateSubscription = this.usersService.resetPasswordKey(this.applicationUserFormGroup.getRawValue()).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.router.navigate(['']);
                },
                error: (value) => {
                    this.isSaving = false;
                    this.snackBar.open(value.error.message, 'Close');
                }
            });
        }
    }

    checkFlag() {
        return !this.resetPasswordKey;
    }

    dontHaveEmailDialog() {
        this.matDialog.open(DialogSimpleComponent, {
            data: {
                title: 'Reset Login Password',
                message: 'Please contact Administrator to reset login password. \n \n Administrator is reachable at: \n <b>Phone no:</b> 2433777 \n <b>Whatsapp:</b> +6738976337 \n <b>Email:</b> nbt.customercare@inchcape.com.bn',
                buttonPositive: 'Okay'
            },
            panelClass: 'mat-dialog-sm'
        })
    }
}
